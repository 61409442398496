.swagger-container {
  margin: 20px; 
}

.section {
  margin-bottom: 20px;
}

.section-header {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 10px;
  cursor: pointer;
}



.section-header h3 {
  margin: 0;
  flex-grow: 1;
  font-size: 20px;
  color: #333;
}

.section-content {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ddd;
}

.api-section {
  margin-bottom: 10px;
}

.odd-section {
  background-color: #f5f5f5;
}

.even-section {
  background-color: #f1f1f1;
}

.api-summary {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
  cursor: pointer;
}



.api-summary h4 {
  margin: 0;
  flex-grow: 1;
  font-size: 18px;
  color: #555;
}
.api-subsection .api-summary{
  background:#dcdcdc;
}

.api-subsection .api-summary:hover {
  background-color: #CCC;
}

.toggle-icon {
  font-size: 16px;
  margin-left: 10px;
}

.hidden .api-doc {
  display: block;
}

.api-doc {
  display: none;
  margin-top: 10px;
}

.api-request h5,
.api-response h5 {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.api-request ul,
.api-response ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.api-request ul li,
.api-response ul li {
  margin-bottom: 5px;
  font-size: 14px;
  color: #777;
}
