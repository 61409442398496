@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  font-family: 'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important;
}

body {
  margin: 0;
  font-family: -apple-system, 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.load-bg{
  position:fixed;
  left:0; top:0;
  width:100vw;
  z-index:100;
  height: 100vh;

  background:rgba(255,255,255,0.7);
}
.loading-container {
  display: flex;
  margin-top:30vh;
  align-items:center;
  justify-content: center;
}
.loading-container.complete *{
  display:none;
}
.loading-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
  opacity:0.5;
  animation: loading 1s infinite;
}

.loading-text {
margin:20px; 
}

.loading-circle:nth-child(2) {
  animation-delay: 0.2s;
  
}

.loading-circle:nth-child(3) {
  animation-delay: 0.4s;
}

.loading-circle:nth-child(4) {
  animation-delay: 0.6s;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    opacity:0.9;
  }
  100% {
    transform: scale(1);
    opacity:0.5;
  }
}
.button:hover{ opacity:0.7; }
