.right-panel {
  width: 400px;
  height: 100vh;
  background-color: var(--secondary-color);
  position: fixed;
  overflow-y:auto;
  color:#FFF;
  overflow-x:hidden;
  top: 0;
  right: 0;
  transition: transform 0.3s ;
  z-index: 999;
}

.rtab {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: var(--secondary-color);
  color: #FFF;
  padding: 18px 8px;
  cursor: pointer;
  z-index: 999;
  transition: right 0.3s ;
}
.rtab:hover { opacity:0.9; }
.hide-button {
  position: absolute;
  top: 0px;
  width:100%;
  right: 10px;
  background-color: transparent;
  color: #555; 
  background:#CCC;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.right-panel .main{ padding-top:20px; }
.tab.visible {
  right: 0;
}
.logout{ position:fixed; bottom:0px; width:100%; background:rgba(0,0,0,0.5); padding:10px; cursor:pointer;  }
.logout:hover{ background:rgba(0,0,0,0.9); }