.auth-container {
  margin: 20px;
}
.listloan, .listsi{
  width:80%; overflow:auto; 
  padding-bottom: 10px;
  margin: 0 auto;
  box-shadow: 0 0 3px inset #999;
}
.service-worker-list {
  display: inline-flex;
 
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  overflow-y:hidden;
  height:250px;
  border-radius:3px;
}
.listsi{
  background:#e6dbc7;
} 
.listloan{
  background:#f1d0c1;
}

.service-worker-card {
  background-color: #f1f1f1;
  border-radius: 2px;
  padding: 10px;
  margin: 10px;
  width: 180px;
  height:180px;
  border:1px solid #999;
  cursor: pointer;
  transition: background-color 0.3s ease， box-shadow 0.3s ease;
  box-shadow: -3px 1px 4px rgba(0, 0, 0, 0.5);
}

.service-worker-card.active {
  background-color: #e0e0e0;
  height:220px; 
  width:300px;
  box-shadow: none;
}
.service-worker-card.active:hover {
  background-color: #e0e0e0;
  height:220px; 
  width:300px;
  box-shadow: none;
  transform:perspective(1000px) rotateY(0deg);
}

.service-worker-card h3 {
  font-weight: bold;
}

.auth-methods {
  margin-top: 10px;
  padding: 4px;
  overflow:auto;
}

.auth-methods button {
  margin: 3px auto;
  width: 100%;
}

.auth-methods button:hover {
  opacity: 0.5;
}
