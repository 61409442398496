.settings-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.settings-page__title {
  margin-bottom: 20px;
  font-size: 24px;
}

.settings-page__section {
  margin-bottom: 30px;
}

.settings-page__section-title {
  margin-bottom: 10px;
  font-size: 18px;
}

.settings-page__input-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.settings-page__input-group label {
  flex: 0 0 150px;
  margin-right: 10px;
  font-weight: bold;
}

.settings-page__input-group input,
.settings-page__input-group textarea {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.settings-page__input-group textarea {
  height: 100px;
}

.settings-page__input-group input[readonly] {
  background-color: #f9f9f9;
  cursor: pointer;
}

.settings-page__input-group input:focus,
.settings-page__input-group textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}
