.transfers-container {
  display: flex;
 height:210px; overflow:hidden;
  margin: 50px auto;
  background: #E9e9e9;
  width: 80%;
  padding: 10px;
}

.transfers-container .btngroup{
  flex-direction: column;
  align-items: flex-start;
  width:100px;
}
.transfers-container button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px;
  width:80px;
  color: var(--secondary-color);
  background:transparent;
 
}
.transfers-container button.active{
  color:#FFF;
  background: var(--primary-color);

} 
@media screen and (max-width: 440px) {
  
    .transfers-container > .btngroup {width: 100px; }
    .transfers-container > div {width: calc(100% - 105px); overflow-x:hidden; }
    .transfers-container .instructions{   display:none;    }
}


@media screen and (min-width: 441px) {
 
  
.transfers-container > div { display:flex; width:350px; overflow:auto; }
.transfers-container > div label, .transfers-container input, .transfers-container select { font-size:12px;}
.transfers-container > div >div{ width:300px; }
.transfers-container .instructions{ width: calc(100% - 450px); display:block; text-align:center; vertical-align:middle; margin:10px; padding:10px; background:#eecdaf; border-radius:3px; }

.transfers-container > div > div button { background:#999; color:#FFF; font-size:14px; width:100%; text-align:center; display:block; }
.transfers-container > div > div button:hover { background:var(--primary-color);  }
.transfers-container button sup {
  font-size: 12px;
  margin-left: 5px;
}
.add-payee-form select{ width: 100px; } 
.add-payee-form #accountNumber{ width: calc( 100% - 115px); }

}