.transaction-history {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  height:100%;
}

.transaction-history__title {
  margin-bottom: 20px;
  font-size: 24px;
}

.transaction-history__filters {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.transaction-history__filters input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.transaction-history__table {
  width: 100%;
  border-collapse: collapse;
}

.transaction-history__table th,
.transaction-history__table td {
  padding: 8px;
  border: 1px solid #ccc;
}
.transaction-history__table .sides{ width:100px; }
.transaction-history__table th {
  background-color: #f9f9f9;
  font-weight: bold;
  text-align: left;
}

.transaction-history__table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transaction-history__table tr:hover {
  background-color: #f1f1f1;
}

.transaction-history__table tr:last-child {
  border-bottom: none;
}
