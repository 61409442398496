.api-update-container {
padding:6px; height:100%;
background:#ffffff; color:#000000;
}
.api-update-container th{ background:var(--primary-color); color:#FFF; }
.api-update-container td{
border:none;
}
.tab-menu {
  display: flex;
  justify-content: space-between;

}

.tab-item {
  padding: 10px 10px;
  font-size: 16px;
  font-weight: bold;
  color: #070707;
  background-color: transparent;
  border: none; 
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease ;
}
.tab-item svg{
   filter:brightness(0); height:20px; 
  }
  .tab-item.active svg{
    filter:brightness(100); height:20px; 
   }  


.tab-item:hover {
  background-color:#999; color:#FFF;
  box-shadow:0 0 10px #666 inset;

}

.tab-item.active {
  color: #FFF;
  background:var(--primary-color);
 
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}


label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 14px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 2px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.success-message {
  color: #28a745;
  margin-top: 10px;
  text-align: center;
}
