.api{  animation: slide-in 0.3s ease; }
.service-worker-list {
  margin-bottom: 32px;
}

.function-editor button,
.api-update-container button{
width:100%;
}
.service-worker-card {

  position: relative;
  transform:perspective(500px) rotateY(20deg);
  transform-style: preserve-3d;
  transition: transform 0.3s;
}

.service-worker-card.active {

  position: relative; 
  transform: rotateY(0deg);
}
.service-worker-card:hover {
  transform:perspective(500px) rotateY(-20deg);
  box-shadow:0 0 2px #999;
}

.service-worker-card:hover h3 {
  color:#999;
}
.service-worker-card .card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  transition: transform 0.3s;
}

.service-worker-card:hover .card-content {
  transform: rotateY(0deg);
}


